import { PanelData } from '@grafana/data';

/**
 * Format and clean up the panel data.
 * All series are expected to be time
 * series and have unique field names.
 * @param data The data object generated by the panel.
 * @returns A formatted object of panel data.
 * `{ timestamp : { field : value, ... }, ... }`
 */
export function format(data: PanelData): { [index: number]: { [index: string]: number } } {
  const formatted: { [index: number]: { [index: string]: number } } = {};
  data.series.forEach((frame) => {
    frame.fields
      .find((field) => field.type === 'time')
      ?.values.toArray()
      .forEach((t: number, i: number) => {
        frame.fields
          .filter((field) => field.type === 'number')
          .forEach((field) => {
            formatted[t] = formatted[t] || {};
            formatted[t][frame.name as string] = field.values.get(i);
            formatted[t][field.name] = field.values.get(i);
          });
      });
  });
  return formatted;
}
